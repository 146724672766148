let $error_help = $('.error-messages');

export function toggleLoading(s, i) {
    if ($(s).is(":visible")) {
        $(s).css("cssText", "display: none !important;");
        $(i).prop("disabled", true);
        $(i).show();
    } else {
        $(i).css("cssText", "display: none !important;");
        $(s).show();
    }
}

export function deleteDialog(id, name, title, button, url, success = null) {
    let swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-danger me-2",
            cancelButton: "btn btn-outline-danger"
        },
        buttonsStyling: false
    });

    swalWithBootstrapButtons.fire({
        title: title,
        text: name,
        icon: "warning",
        backdrop: true,
        showCancelButton: true,
        confirmButtonText: button,
        showLoaderOnConfirm: true,
        cancelButtonText: $error_help.data('cancel'),
        allowOutsideClick: () => !Swal.isLoading(),
        async preConfirm() {
            return fetch(url, {
                method: "POST",
                mode: "same-origin",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {"Content-Type": "application/x-www-form-urlencoded"},
                referrerPolicy: "no-referrer",
                body: "ID=" + id
            }).then(response => {
                return response.json();
            });
        }
    }).then(function (result) {
        if (result.isConfirmed) {
            if (result.value.success) {
                success(result.value);
                swalSuccess(result.value.data);
            } else {
                swalError(result.value.data);
            }
        }
    });
}

export function showLoading() {
    Swal.fire({
        title: $error_help.data('progress'),
        willOpen() {
            Swal.showLoading();
        },
        didClose() {
            Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCancelButton: false,
        showDenyButton: false
    }).then();
}

export function swalSuccess(data) {
    Swal.fire({
        icon: 'success',
        showConfirmButton: false,
        timer: 2000,
        title: data
    }).then();
}

export function swalError(data) {
    Swal.fire({
        icon: 'error',
        showConfirmButton: false,
        timer: 2000,
        title: $error_help.data('error'),
        text: data
    }).then();
}

export function swalUnknown(e) {
    if (e.status > 0) {
        Swal.fire({
            icon: 'error',
            showConfirmButton: false,
            timer: 3000,
            title: $error_help.data('unknown'),
            text: $error_help.data('help')
        }).then();
    }
}

export function storeFilters() {
    let id = $('main').prop('id');
    let filter = $('.filter-form');

    sessionStorage.setItem(id + '_filters', JSON.stringify(filter.serializeArray()));
}

export function loadFilters() {
    let id = $('main').prop('id');
    let filters = sessionStorage.getItem(id + '_filters');

    if (filters === null) {
        return;
    }

    filters = JSON.parse(filters);

    filters.forEach(e => {
        $('#' + e.name).val(e.value);
    });

    $('.select2').trigger('change');
}

export function checkResponsive() {
    return window.matchMedia('screen and (max-width: 768px)').matches;
}